footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  margin-top: 4em;
  position: relative;
}

.cont-main-footer div:first-child,
.cont-main-footer div:last-child {
  width: 100px;
}

.logo-footer {
  width: 220px;
  margin: 2em auto;
}

.logo-footer img {
  width: 100%;
}

.manoIzq,
.manoDer {
  position: absolute;
}

.manoIzq img {
  width: 540px;
}

.manoDer img {
  width: 440px;
}

.manoIzq {
  bottom: -40px;
  left: -100px;
}

.manoDer {
  bottom: -40px;
  right: 250px;
}

footer h5 {
  font-size: 0.6em;
}

footer a {
  text-decoration: none;
  color: var(--black);
}

footer a:hover {
  color: var(--primary);
}

@media (max-width: 880px) {
  .manoIzq {
    bottom: -40px;
    left: -150px;
  }

  .manoDer {
    bottom: -40px;
    right: 200px;
  }

  footer h5 {
    margin-bottom: 4em;
  }
}

@media (max-width: 480px) {
  footer {
    margin-top: 2em;
    font-size: 0.8em;
  }

  .logo-footer {
    width: 120px;
    margin: 2em auto;
  }

  .manoDer img {
    width: 200px;
  }

  .manoIzq img {
    width: 240px;
  }

  .manoIzq {
    bottom: 100px;
    left: -50px;
  }

  .manoDer {
    bottom: 95px;
    right: 60px;
  }

  footer h5 {
    margin-top: 5em;
  }
}
