.cont-main-docencia h2 {
  text-align: center;
  padding-bottom: 2em;
  width: 150px;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: var(--bold);
  font-size: 1.5em;
  color: var(--primary);
  margin: auto;
}

.cont-banner-docencia {
  width: 100%;
}

.cont-banner-docencia img {
  width: 100%;
}
