.cont-main-tecnica-bestia {
  background-color: var(--primary);
  padding: 2em;
}

.cont-txt-bestia {
  width: 50%;
  margin: auto;
  color: var(--white);
  background-color: transparent;
  text-align: justify;
}

.cont-txt-bestia h2 {
  text-transform: uppercase;
  padding-bottom: 1em;
}

.cont-txt-bestia p:last-child {
  padding-bottom: 1em;
}

.cont-tecnica-bestia {
  text-align: center;
  color: var(--white);
}

.cont-tecnica-bestia h3 {
  font-size: 1.5em;
  font-family: var(--semiBold);
}

.cont-tecnica-bestia span {
  font-family: var(--semiBold);
}

.cont-link-lorenzo {
  width: 100%;
  text-align: center;
  margin-top: 4em;
}

.cont-link-lorenzo a {
  display: inline-block;
  background-color: var(--black);
  margin: 10px;
  padding: 5px 5px;
  width: 250px;
  text-decoration: none;
  color: var(--white);
  text-transform: uppercase;
}

@media (max-width: 880px) {
  .cont-txt-bestia {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .cont-main-tecnica-bestia {
    background-color: var(--primary);
    padding: 1em;
  }

  .cont-link-lorenzo {
    margin-top: 3em;
    font-size: 0.7em;
  }
}
