.cont-title-section {
  text-align: center;
  background-color: var(--primary);
  padding: 1em 0 2em 0;
}

.cont-title-section h2 {
  margin: 0;
  width: 150px;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: var(--bold);
  font-size: 1.5em;
  color: var(--black);
  margin: 0 auto;
  padding: 10px;
}
