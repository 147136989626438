.cont-main-direction {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 18em 4em 0 4em;
}

.cont-main-direction h2 {
  grid-column: span 2;
  text-align: center;
  padding-bottom: 2em;
  width: 150px;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: var(--bold);
  font-size: 1.5em;
  color: var(--black);
  margin: auto;
}

.rayito {
  width: 100px;
  margin-left: -2.5em;
}

.rayito img {
  width: 100%;
}

.cont-pinches-top-direccion {
  width: 100%;
  position: absolute;
  top: 0;
}

.cont-pinches-top-direccion img {
  width: 100%;
}

.cont-main-txt-direccion {
  color: var(--primary);
  width: 90%;
  text-align: justify;
}

.cont-main-txt-direccion span {
  font-family: var(--semiBold);
}

.cont-main-txt-direccion h3 {
  font-family: var(--semiBold);
  color: var(--secondary);
  font-size: 1.2em;
  padding-bottom: 1em;
}

.cont-main-txt-direccion ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cont-main-txt-direccion,
.cont-main-txt-grid {
  padding-top: 0;
  margin: auto;
}

.cont-main-grid {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.grid-item {
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
}

.item3 {
  grid-column: span 2;
  grid-row: span 2;
}

.gridU {
  grid-row: span 2;
}

.logo-azul {
  grid-column: span 2;
  margin: 4em auto;
  width: 100px;
}

.logo-azul img {
  width: 100%;
}

@media (max-width: 880px) {
  .cont-main-direction {
    padding: 14em 2em 0 2em;
  }

  .cont-main-direction h2 {
    grid-column: span 2;
  }

  .cont-main-txt-direccion h3 {
    text-align: start;
  }
}

@media (max-width: 480px) {
  .cont-main-direction {
    display: block;
    padding: 0;
  }

  .cont-main-txt-direccion {
    text-align: start;
    font-size: 0.7em;
  }

  .cont-main-direction h2 {
    grid-column: span 2;
    padding-top: 130px;
    color: var(--secondary);
  }

  .rayito {
    margin-left: -10px;
  }

  .cont-main-grid {
    padding: 0 1em;
    margin-top: 2em;
    min-height: 1000px;
  }
}
