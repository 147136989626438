.cont-main-bio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 100;
  padding-top: 400px;
}

.cont-logo-hands {
  width: 100%;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cont-pinches-top,
.cont-pinches-bottom {
  width: 100%;
  position: absolute;
}

.cont-pinches-top img,
.cont-pinches-bottom img {
  width: 100%;
}

.cont-pinches-top {
  top: 0;
}

.cont-pinches-bottom {
  bottom: 0;
  transform: rotate(180deg);
  z-index: -100;
}

.blue-hand1 {
  width: 350px;
  transform: rotate(60deg);
  position: absolute;
  left: 50px;
  top: -120px;
}

.blue-hand2 {
  width: 690px;
  transform: rotate(20deg);
  position: absolute;
  right: -140px;
  top: 90px;
}

.cont-logo-bio {
  width: 600px;
  z-index: 3000;
}

.cont-logo-bio img {
  width: 100%;
}

.cont-txt-bio {
  width: 50%;
  text-align: justify;
  font-family: var(--regular);
  color: var(--primary);
  margin: 140px 0 330px 0;
}

.cont-txt-bio h1 {
  font-size: 1em;
  font-family: var(--semiBold);
}

.cont-txt-bio p {
  font-size: 1.1em;
}

.cont-txt-bio p:last-child {
  font-family: var(--semiBold);
}

.link-yt {
  text-align: center;
  text-decoration: none;
  color: var(--primary);
  border-radius: 10px;
  outline: 1px solid var(--primary);
  display: block;
  width: 250px;
  padding: 10px 15px;
  margin: 3em auto 0 auto;
  transition: all 0.2s;
}
.link-yt:hover {
  color: var(--white);
  background-color: var(--primary);
}

@media (max-width: 880px) {
  .cont-main-bio {
    padding-top: 240px;
  }

  .blue-hand1 {
    width: 250px;
    left: 30px;
    top: -100px;
  }

  .blue-hand2 {
    width: 490px;
    right: -100px;
    top: 50px;
  }

  .cont-logo-bio {
    width: 360px;
  }

  .cont-txt-bio {
    width: 50%;
    margin: 140px 0 200px 0;
  }
}

@media (max-width: 480px) {
  .cont-main-bio {
    padding-top: 150px;
  }

  .blue-hand1 {
    width: 140px;
    left: -20px;
    top: -60px;
  }

  .blue-hand2 {
    width: 260px;
    right: -90px;
    top: 30px;
  }

  .cont-logo-bio {
    width: 210px;
  }

  .cont-txt-bio {
    width: 90%;
    margin: 120px 0 60px 0;
    font-size: 0.7em;
    text-align: left;
  }

  .link-yt {
    width: 200px;
    margin: 3em auto 4em auto;
  }
}
