.cont-main-conv {
  text-align: center;
  width: 80%;
  margin: auto;
}

.cont-main-conv h2 {
  padding: 2em;
  text-transform: uppercase;
  font-family: var(--bold);
  font-size: 1.5em;
  color: var(--secondary);
}

.opcion-lista-conv {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1em;
}

.opcion-lista-conv p {
  margin-bottom: 0;
}

.cont-circulito {
  display: block;
  width: 20px;
  height: 20px;
  background-color: var(--secondary);
  border-radius: 50%;
  margin-right: 0.5em;
  padding: 10px;
}

.custom-fest p {
  margin-bottom: 0;
}

.li-festival {
  align-items: flex-start;
  padding-bottom: 1em;
}

@media (max-width: 480px) {
  .cont-circulito {
    margin-right: 1em;
  }

  .cont-main-conv {
    width: 90%;
  }
}
