.cont-main-slider {
  background-color: var(--black) !important;
}

.item {
  width: 100%;
  height: 400px;
  margin: 30px 0;
}

.item img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.item-logo img {
  object-fit: contain !important;
}
