@font-face {
  font-family: BoldF;
  src: url("./assets/fonts/SourceSansPro-Black.ttf");
}

@font-face {
  font-family: SemiBold;
  src: url("./assets/fonts/SourceSansPro-Semibold.ttf");
}

@font-face {
  font-family: Regular;
  src: url("./assets/fonts/SourceSansPro-Regular.ttf");
}

:root {
  --bold: BoldF;
  --semiBold: SemiBold;
  --regular: Regular;
  --primary: #276ab2;
  --secondary: #a21920;
  --third: #f8ac3f;
  --white: #ffffff;
  --black: #000000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.App {
  display: flex;
  justify-content: center;
}

main {
  max-width: 1440px;
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 1.2em;
}

.btn-to-up {
  position: fixed;
  bottom: 20px;
  right: 1px;
  background-color: var(--third);
  width: 40px;
  height: 40px;
  border: none;
  z-index: 30000;
}

.btn-to-up svg {
  width: 20px;
  height: 100%;
}
